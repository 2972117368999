<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<style>
@import "css/main.css";
@import "@lipsurf/common/base.css";
@import "@lipsurf/common/components.css";
@import "css/transitions.css";
@import "css/specials.css";
</style>
