import 'vuetify/styles';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueAnimateOnScroll from 'vue3-animate-onscroll';

// Vue.config.productionTip = false;

const app = createApp(App);

app.use(router);
app.use(vuetify);
app.use(VueAnimateOnScroll);
app.mount('#app');